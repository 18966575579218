import { useState } from "react";
import { Swiper, SwiperSlide } from "../components/Swiper";
import Standings from "./Standings";
import { Button, Grid } from "@mui/material";
import PlayerSchedule from "./PlayerSchedule";
import { useQuery } from "react-query";
import { playerToken, tournamentToken } from "../contexts/auth";
import { getTournament } from "../api/tournaments";
import { getTricks } from "../api/tricks";
import { getPlayer } from "../api/player";
import { PlayerDrawer } from "./PlayerDrawer";
import TableScore from "./TableScore";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import { PlayerRoundType, PlayerType } from "../data/types";
import { TrickInfoType, calcRoundInfo } from "../data/tricks";

export type MyRound = {
  round: PlayerRoundType;
  info: TrickInfoType;
};

export default function PlayerView(props: any) {
  const pageNames = ["Schedule", "TableScore", "Standings"];
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>(null);

  const {
    isLoading: isLoadingTournament,
    isError: isErrorTournament,
    data: tournament,
  } = useQuery({
    queryKey: ["tournament", tournamentToken()],
    queryFn: () => getTournament(),
    refetchInterval: 5000,
  });

  const {
    isLoading: isLoadingTricks,
    isError: isErrorTricks,
    data: tricks,
  } = useQuery({
    queryKey: ["tricks", tournamentToken()],
    queryFn: () => getTricks(),
    refetchInterval: 1000,
  });

  const {
    isLoading: isLoadingPlayer,
    isError: isErrorPlayer,
    data: player,
  } = useQuery({
    queryKey: ["player", playerToken()],
    queryFn: () => getPlayer(),
    refetchInterval: 5000, // TODO get rid of this?
  });

  if (isLoadingTournament || isLoadingTricks || isLoadingPlayer)
    return <h1>Loading...</h1>;
  if (isErrorTournament || isErrorTricks || isErrorPlayer)
    return <h1>Error loading, please refresh</h1>;

  const myRounds: MyRound[] = (player as PlayerType).rounds
    .map((round) => {
      return {
        round: round,
        info: calcRoundInfo(tricks.tricks, player.player_id, round.round_id),
      };
    })
    .sort((a, b) => a.round.round - b.round.round);

  const expectedRound = myRounds.find((r) => r.info.tricks < 8);
  if (!expectedRound) {
    tournament.complete = true;
    if (currentRound !== myRounds.length - 1)
      setCurrentRound(myRounds.length - 1);
  } else {
    if (currentRound !== expectedRound?.round.round) {
      console.log("about to change round", myRounds);
      console.log("setting current round to", expectedRound?.round.round);
      setCurrentRound(expectedRound?.round.round ?? 0);
    }
  }

  const players = tournament.players.reduce((acc: any, player: any) => {
    acc.set(player.id, player.name);
    return acc;
  }, new Map<number, string>());

  return (
    <>
      <Grid container spacing={1}>
        {pageNames.map((name, i) => (
          <Grid item xs={4} spacing={3} justifyContent="flex-end">
            <Grid container justifyContent={"center"} key={i}>
              <Button
                onClick={() => {
                  swiper?.slideToLoop(i);
                }}
                size="small"
                variant={i === pageIndex ? "outlined" : "text"}
                sx={{
                  paddingLeft: 0.5,
                  paddingRight: 0.5,
                }}
              >
                {name}
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Swiper
            loop={true}
            on={{
              slideChange: (e: any) => {
                setPageIndex(e.realIndex);
              },
              init: (e: any) => {
                setSwiper(e);
              },
            }}
            initialSlide={pageIndex}
          >
            <SwiperSlide>
              <PlayerSchedule
                tournament={tournament}
                tricks={tricks.tricks}
                currentRound={currentRound}
                players={players}
                player={player}
                myRounds={myRounds}
              />
            </SwiperSlide>
            <SwiperSlide>
              <TableScore
                tournament={tournament}
                tricks={tricks.tricks}
                currentRound={currentRound}
                players={players}
                player={player}
                suits={tricks.suits}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Standings
                tournament={tournament}
                tricks={tricks.tricks}
                players={players}
              />
            </SwiperSlide>
          </Swiper>
        </Grid>
      </Grid>
      <SwipeableEdgeDrawer drawerText="config">
        <PlayerDrawer player={player.name} emoji={player.emoji} />
      </SwipeableEdgeDrawer>
    </>
  );
}
