import { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Avatar,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import { useTheme } from "@mui/material/styles";
import { SuitSelector } from "../components/SuitSelector";
import { Link } from "react-router-dom";
import {
  PlayerRoundType,
  PlayerType,
  RoundType,
  TournamentType,
  TrickType,
} from "../data/types";
import { calcRoundInfo, tricksForRound } from "../data/tricks";
import { playerToken, tournamentToken } from "../contexts/auth";
import { useMutation } from "react-query";
import { queryClient } from "../api/query";
import { grey } from "@mui/material/colors";
import { DealerDialog } from "../components/DealerDialog";
import { ScoreDialog } from "../components/ScoreDialog";

type TableScoreProps = {
  tricks: TrickType[];
  tournament: TournamentType;
  currentRound: number;
  players: Map<number, string>;
  player: PlayerType;
  suits: any;
};

export const undoTrick = async (round: number) => {
  console.log("undoing trick", round);
  const result = await fetch(
    `/api/player/${playerToken()}/round/${round}/undo`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: "{}",
    },
  );
  return result.json();
};

export const scoreTrick = async ({
  points,
  type,
  round,
  hand_player,
  suit,
  scoreFor,
  trick,
}: {
  points: number;
  type?: string;
  round: number;
  hand_player?: number;
  suit: string;
  scoreFor?: number;
  trick: number;
}) => {
  const body = JSON.stringify({
    hand: type ?? "tricks",
    points: points,
    hand_player: hand_player || -1,
    suit: suit === "" ? null : suit,
    score_for: scoreFor,
  });

  const result = await fetch(
    `/api/player/${playerToken()}/round/${round}/score`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    },
  );

  return result.json();
};

export const setDealers = async ({
  dealer1,
  dealer2,
  round,
}: {
  round: number;
  dealer1: number;
  dealer2: number;
}) => {
  const body = JSON.stringify({
    dealer1,
    dealer2,
  });

  console.log("set dealers body", body);
  const result = await fetch(
    `/api/tournament/${tournamentToken()}/round/${round}/dealers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    },
  );

  return result.json();
};

export const setSuit = async ({
  round,
  suit,
  trick,
}: {
  round: number;
  suit?: string;
  trick: number;
}) => {
  const body = JSON.stringify({
    suit: suit,
    trick: trick,
  });

  const result = await fetch(
    `/api/player/${playerToken()}/round/${round}/suit`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    },
  );

  return result.json();
};

const suitLookup: any = {
  spades: "♠",
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
};

function TableScore({
  tricks,
  tournament,
  currentRound,
  players,
  player,
  suits,
}: TableScoreProps) {
  const theme = useTheme();
  const [dealerDialog, setDealerDialog] = useState<boolean>(false);
  const [secondDealerDialog, setSecondDealerDialog] = useState<boolean>(false);
  const [showPrevRound, setShowPrevRound] = useState<boolean>(false);
  const [ourScoreDialog, setOurScoreDialog] = useState<boolean>(false);
  const [theirScoreDialog, setTheirScoreDialog] = useState<boolean>(false);
  const [dealerIndex, setDealerIndex] = useState<number>(0);
  const [suit, setSuitState] = useState("");

  const visibleRound =
    showPrevRound && currentRound > 0 ? currentRound - 1 : currentRound;
  const currentTable = player.rounds[visibleRound].tablenum;
  const round: PlayerRoundType =
    player.rounds.find((r) => r.round === visibleRound) ?? player.rounds[0];
  const tround: RoundType | undefined = tournament.rounds.find(
    (r) => r.round === currentRound && r.tablenum === currentTable,
  );

  const dealer1 = tround?.dealer1 ?? 0;
  const dealer2 = tround?.dealer2 ?? 2;
  const tInfo = {
    table: round.tablenum,
    partnerName: players.get(round.partner_id) ?? "",
    player: calcRoundInfo(tricks, player.player_id, round.round_id),
    partner: calcRoundInfo(tricks, round.partner_id, round.round_id),
    partnerId: round.partner_id,
    opponent1Id: round.opponent1_id,
    opponent2Id: round.opponent2_id,
    oppenent1Name: players.get(round.opponent1_id) ?? "",
    oppenent2Name: players.get(round.opponent2_id) ?? "",
  };

  const trickSet = new Set<number>();
  tricks
    .filter((t) => t.round_id === round.round_id)
    .every((t) => trickSet.add(t.trick_id));
  const trickCount = trickSet.size;
  const dealerMap = [dealer1, dealer2, dealer1 ^ 1, dealer2 ^ 1];
  const playerArray = [
    tround?.north_player ?? 0,
    tround?.south_player ?? 0,
    tround?.east_player ?? 0,
    tround?.west_player ?? 0,
  ];
  console.log("playerArray", playerArray);

  const dealerName = (round: number) => {
    const index = playerArray[dealerMap[round % 4]];
    const player = players.get(index);
    return player ?? "";
  };

  const dbSuit = suits?.[round?.round_id]?.[trickCount];
  if (suit !== dbSuit && (dbSuit || suit !== "")) {
    console.log("setting suit", dbSuit);
    console.log("round", round?.round_id);
    setSuitState(dbSuit ?? "");
  }

  const scoreMutation = useMutation({
    mutationFn: scoreTrick,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["x"]);
      console.log("trickCount", trickCount);
      if (trickCount >= 7) {
        console.log("setShowPrevRound");
        setShowPrevRound(true);
      }

      setOurScoreDialog(false);
      setTheirScoreDialog(false);
      console.log("Clear suit");
      setSuitState("");
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  const undoMutation = useMutation({
    mutationFn: undoTrick,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tricks"]);
      console.log("Completed undo", trickCount, " ", currentRound);
      setShowPrevRound(false);
      console.log("Cleared showPrevRound");
    },
    onError: (error) => {
      console.log("ERROR in undo:", error);
    },
  });

  const suitMutation = useMutation({
    mutationFn: setSuit,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tricks"]);
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  const dealerMutation = useMutation({
    mutationFn: setDealers,
    onSuccess: (data) => {
      console.log("mutated dealerMap", data);
      queryClient.invalidateQueries(["tournament", tournamentToken()]);
    },
    onError: () => {},
  });

  const handleSuitChange = (suit: string) => {
    console.log("suit", suit);
    setSuitState(suit);
    suitMutation.mutate({
      round: round.round_id,
      trick: trickCount,
      suit: suit,
    });
  };

  const roundTricks = tricksForRound(
    player.player_id,
    tricks,
    players,
    round.round_id,
  );

  const theirScore = roundTricks[roundTricks.length - 1]?.them ?? 0;

  const dealerCallback = (index: number) => {
    setDealerDialog(false);
    if (index === -1) return;
    setDealerIndex(index);
    setSecondDealerDialog(true);
  };

  const saveDealers = (dealer2: number) => {
    setSecondDealerDialog(false);
    if (dealer2 === -1) return;

    let offset = dealerIndex < 2 ? 2 : 0;
    dealerMap[0] = dealerIndex;
    dealerMap[1] = dealer2 + offset;
    dealerMap[2] = dealerIndex ^ 1;
    dealerMap[3] = (dealer2 + offset) ^ 1;

    console.log("mutating dealerMap", dealerMap);
    dealerMutation.mutate({
      dealer1: dealerIndex,
      dealer2: dealer2 + offset,
      round: round.round_id,
    });
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "1em" }}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <SuitSelector suit={suit} setSuit={handleSuitChange} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h5" marginTop={1}>
            Round {visibleRound + 1}
            {showPrevRound && "*"} Table {currentTable + 1}
          </Typography>
          <Typography variant="body1">
            {player.name} {tInfo.player.loners} loners and{" "}
            {tInfo.player.euchres} euchres
          </Typography>
          <Typography variant="body1">
            {tInfo.partnerName} {tInfo.partner.loners} loners and{" "}
            {tInfo.partner.euchres} euchres
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end">
            {trickCount !== 0 ? (
              <Button
                onClick={() => {
                  console.log(
                    "Undoing last trick",
                    visibleRound,
                    "-",
                    currentRound,
                  );
                  undoMutation.mutate(visibleRound);
                }}
              >
                <Avatar
                  sx={{
                    height: 56,
                    width: 56,
                    mt: 1,
                    mb: 0,
                    bgcolor: theme.palette.primary.main,
                  }}
                >
                  <UndoIcon />
                </Avatar>
              </Button>
            ) : (
              currentRound > 0 &&
              currentRound > tournament.locked_rounds && (
                <Link to="#">
                  <Button
                    variant="contained"
                    onClick={() => {
                      console.log("Setting showPrevRound");
                      setShowPrevRound(true);
                    }}
                    sx={{ height: 56, mt: 2 }}
                  >
                    Prev Round
                  </Button>
                </Link>
              )
            )}
          </Box>
        </Grid>
        {trickCount < 8 ? (
          <>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                sx={{ minHeight: 60 }}
                onClick={() => setOurScoreDialog(true)}
              >
                Score Us
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                sx={{ minHeight: 60 }}
                onClick={() => setTheirScoreDialog(true)}
              >
                Score Them
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Link
              to="#"
              onClick={() => {
                console.log("Clearing showPrevRound");
                setShowPrevRound(false);
              }}
            >
              <Button variant="contained" fullWidth sx={{ minHeight: 60 }}>
                Finish Round
              </Button>
            </Link>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography variant="h6" textAlign="center">
            {player.name} & {tInfo.partnerName}
          </Typography>
          <Typography variant="h1" textAlign="center">
            {tInfo.player.score}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" textAlign="center">
            {tInfo.oppenent1Name} & {tInfo.oppenent2Name}
          </Typography>
          <Typography variant="h1" textAlign="center">
            {theirScore}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" width="45%">
                    <Link to="#" onClick={() => setDealerDialog(true)}>
                      Dealer
                    </Link>
                  </TableCell>
                  {/* <TableCell component="th">Dealer</TableCell> */}
                  <TableCell align="left" width="20%">
                    Score
                  </TableCell>
                  <TableCell align="right" width="35%">
                    Notes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roundTricks.map((trick, i) => (
                  <TableRow key={trick.trick_id}>
                    <TableCell>
                      {i + 1} - {dealerName(i)}
                    </TableCell>
                    <TableCell align="left">
                      {trick.our_points ? trick.points : 0} -{" "}
                      {trick.our_points ? 0 : trick.points}
                    </TableCell>
                    <TableCell align="right">
                      {trick.loner.length > 0 && `${trick.loner} loner`}
                      {trick.euchred.length > 0 && `${trick.euchred} euchred`}
                      {trick.suit && suitLookup[trick.suit]}
                    </TableCell>
                  </TableRow>
                ))}

                {trickCount < 8 &&
                  (Array(8 - trickCount).fill(0) ?? []).map((_, i) => (
                    <TableRow
                      key={i}
                      sx={{ backgroundColor: i === 0 ? grey[900] : "inherit" }}
                    >
                      <TableCell>
                        {i + trickCount + 1} - {dealerName(i + trickCount)}
                      </TableCell>
                      <TableCell align="left">-</TableCell>
                      <TableCell align="right">-</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ScoreDialog
        open={ourScoreDialog}
        player={player.name}
        player_id={player.player_id}
        partner={tInfo.partnerName}
        partner_id={tInfo.partnerId}
        opponent1={tInfo.oppenent1Name}
        opponent1_id={tInfo.opponent1Id}
        opponent2={tInfo.oppenent2Name}
        opponent2_id={tInfo.opponent2Id}
        onClose={(points: number, type: string, hand_id: number) => {
          console.log("Our score: ", points, type, hand_id);

          scoreMutation.mutate({
            points: points,
            type: type,
            round: round.round,
            hand_player: hand_id,
            suit: suit,
            trick: trickCount,
          });

          setOurScoreDialog(false);
        }}
      />
      <ScoreDialog
        open={theirScoreDialog}
        player={tInfo.oppenent1Name}
        player_id={tInfo.opponent1Id}
        partner={tInfo.oppenent2Name}
        partner_id={tInfo.opponent2Id}
        opponent1={player.name}
        opponent1_id={player.player_id}
        opponent2={tInfo.partnerName}
        opponent2_id={tInfo.partnerId}
        onClose={(points: number, type: string, hand_id: number) => {
          console.log("Their score: ", points, type, hand_id);

          scoreMutation.mutate({
            points: points,
            type: type,
            round: round.round,
            hand_player: hand_id,
            suit: suit,
            trick: trickCount,
            scoreFor: tInfo.opponent1Id,
          });

          setTheirScoreDialog(false);
        }}
      />
      <DealerDialog
        open={dealerDialog}
        message="Who has the first deal?"
        player_ids={[0, 1, 2, 3]}
        players={players}
        dealerMap={playerArray}
        onClose={dealerCallback}
      />
      <DealerDialog
        open={secondDealerDialog}
        message="Who has the second deal?"
        player_ids={dealerIndex < 2 ? [2, 3] : [0, 1]}
        dealerMap={playerArray}
        players={players}
        onClose={saveDealers}
      />
    </Box>
  );
}

export default TableScore;
