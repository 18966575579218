import {
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
  useOutlet,
} from "react-router-dom";
import Header from "./components/Header";
import Register from "./pages/Register";
import CreateTourney from "./pages/CreateTourney";
import TourneyList from "./pages/TourneyList";
import Tournament from "./pages/Tournament";
import Player from "./pages/Player";
import PlayerView from "./pages/PlayerView";
import PlayerLanding from "./pages/PlayerLanding";
import TournamentSelect from "./pages/TournamentSelect";
import StandingOverview from "./pages/StandingOverview";
import OTPSignIn from "./pages/OTPSignIn";

export default function App() {
  return (
    <>
      <Routes>
        <Route
          path="/welcome/:tournamentToken/player/:playerToken"
          element={<PlayerLanding />}
        />
        <Route
          path="/tournament/:tournamentToken/player/:playerToken"
          element={<Player />}
        />
        <Route path="/player" element={<PlayerView />} />
        <Route
          path="/tournament/:tournamentToken/select"
          element={<TournamentSelect />}
        />
        <Route
          path="/tournament/:tournamentToken/standings"
          element={<StandingOverview />}
        />
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="signin" element={<OTPSignIn />} />
          <Route path="register" element={<Register />} />
          <Route path="create-tourney" element={<CreateTourney />} />
          <Route path="tournaments" element={<TourneyList />} />
          <Route
            path="tournament"
            element={<SubRouteOrNavigate to="/tournaments" />}
          >
            <Route path=":tournamentToken" element={<Tournament />} />
          </Route>
          <Route path="about" element={<NoMatch />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </>
  );
}

function SubRouteOrNavigate({ to }: { to: string }) {
  const outlet = useOutlet();

  return outlet ? outlet : <Navigate to={to} />;
}

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
      <Outlet />
    </div>
  );
}
