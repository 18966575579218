import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { tournament } from "../data/tourney";
import { setTournamentToken, tournamentToken } from "../contexts/auth";
import { useQuery } from "react-query";
import { getTournament } from "../api/tournaments";
import { PlayerType } from "../data/types";
import IosShareIcon from "@mui/icons-material/IosShare";
import QRCode from "react-qr-code";

const shareLink = (title: string, url: string) => {
  if (navigator.share) {
    console.log("Sharing", title, url);
    navigator
      .share({
        title: title,
        url: url,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  }
};

export default function TournamentSelect() {
  const navigate = useNavigate();
  const token =
    useParams<{ tournamentToken: string }>()["tournamentToken"] || "";
  const tournyUrlPath = `/tournament/${tournamentToken()}/select`;
  const tournyUrl = `${process.env.REACT_APP_HOSTNAME}${tournyUrlPath}`;

  if (token.length > 0 && tournamentToken() !== token) {
    console.log("Setting tournamentToken to", token);
    setTournamentToken(token);
  }

  const { isLoading, isError, data } = useQuery({
    queryKey: ["tournament", tournamentToken()],
    queryFn: () => getTournament(),
    onSuccess: (data) => {
      console.log("Success:", data);
      if (data?.status !== "success") {
        console.log("ERROR:", data);
        throw new Error("Error loading tournament");
      }
    },
    onError: (error) => {
      navigate("/signin");
    },
  });

  if (isLoading) return <h1>Loading...</h1>;
  if (isError) return <h1>Error, please refresh</h1>;

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h2">
            Tournament {tournament.value.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Select your player below</Typography>
        </Grid>
        <Grid item xs={12} paddingTop={1}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Emoji</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="right">Share</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.players
                  .map((player: PlayerType) => {
                    return {
                      name: player.name,
                      emoji: player.emoji,
                      url: `/welcome/${token}/player/${player.token}`,
                    };
                  })
                  .map(
                    ({
                      name,
                      emoji,
                      url,
                    }: {
                      name: string;
                      emoji: string;
                      url: string;
                    }) => (
                      <TableRow key={url}>
                        <TableCell align="right">{emoji}</TableCell>
                        <TableCell>
                          <Link to={url}>{name}</Link>
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            onClick={() =>
                              shareLink(
                                "Euchre Tourney User List",
                                `${process.env.REACT_APP_HOSTNAME}${url}`,
                              )
                            }
                            to="#"
                          >
                            <IosShareIcon />
                          </Link>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={12} justifyContent="flex-end" paddingTop={2}>
          <Typography variant="body2">
            <Link
              onClick={() => shareLink("Euchre Tourney User List", tournyUrl)}
              to="#"
            >
              Share this page
              <IosShareIcon />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <QRCode value={tournyUrl} />
        </Grid>
      </Grid>
    </Container>
  );
}
