import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { tournament } from "../data/tourney";
import { TrickInfoType, playerTourneyInfo } from "../data/tricks";
import { setTournamentToken, tournamentToken } from "../contexts/auth";
import { useQuery } from "react-query";
import { getTournament } from "../api/tournaments";
import { PlayerType } from "../data/types";

export default function Tournament() {
  const navigate = useNavigate();
  const token =
    useParams<{ tournamentToken: string }>()["tournamentToken"] || "";

  if (token.length > 0 && tournamentToken() !== token) {
    console.log("Setting tournamentToken to", token);
    setTournamentToken(token);
  }

  const { isLoading, isError, data } = useQuery({
    queryKey: ["tournament", tournamentToken()],
    queryFn: () => getTournament(),
    onSuccess: (data) => {
      console.log("Success:", data);
      if (data?.status !== "success") {
        console.log("ERROR:", data);
        throw new Error("Error loading tournament");
      }
    },
    onError: (error) => {
      navigate("/signin");
    },
  });

  if (isLoading) return <h1>Loading...</h1>;
  if (isError) return <h1>Error, please refresh</h1>;

  return (
    <>
      <h1>Tournament {tournament.value.name} </h1>
      <h3>
        <Link to={`/tournament/${tournamentToken()}/standings`}>Standings</Link>
      </h3>
      <h3>
        <Link to={`/tournament/${tournamentToken()}/select`}>Select</Link>
      </h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th">Name</TableCell>
              <TableCell align="right">Score</TableCell>
              <TableCell align="right">Loners</TableCell>
              <TableCell align="right">Euchres</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.players
              .map((player: PlayerType) => {
                return {
                  token: player.token,
                  name: player.name,
                  info: playerTourneyInfo(player.player_id),
                };
              })
              .map(
                ({
                  token: pToken,
                  name,
                  info,
                }: {
                  token: string;
                  name: string;
                  info: TrickInfoType;
                }) => (
                  <TableRow key={pToken}>
                    <TableCell>
                      <Link to={`/tournament/${token}/player/${pToken}`}>
                        {name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{info.score}</TableCell>
                    <TableCell align="right">{info.loners}</TableCell>
                    <TableCell align="right">{info.euchres}</TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
